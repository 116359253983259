import { ReactNode, useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { useNavigate } from 'react-router-dom';
import { SiteType } from './types';
import SiteTypeSelector from './SiteTypeSelector';
import { pathSitesCreate } from '../../constants/path';

export default function CreateSiteTrigger({
  insightId,
  renderButton = (onClick) => (
    <Button colorScheme="greenDark" onClick={onClick}>
      <FormattedMessage defaultMessage="Create site" id="psYTXj" />
    </Button>
  ),
}: {
  insightId: string;
  renderButton?: (onClick: () => void) => ReactNode;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSiteType, setSelectedSiteType] = useState<SiteType>(
    SiteType.PROJECT_SITE
  );
  return (
    <>
      {renderButton(onOpen)}
      <ConfirmationModal
        open={isOpen}
        onCancel={onClose}
        title={formatMessage({
          defaultMessage: 'Create a new area to monitor',
          id: '8Wjaea',
        })}
        confirmButtonText={formatMessage({
          defaultMessage: 'Next',
          id: '9+Ddtu',
        })}
        body={
          <SiteTypeSelector
            value={selectedSiteType}
            onChange={(newValue) => {
              setSelectedSiteType(newValue);
            }}
          />
        }
        onConfirm={() => {
          navigate(
            pathSitesCreate(insightId, fragmentFromsiteType(selectedSiteType))
          );
          onClose();
        }}
      />
    </>
  );
}

function fragmentFromsiteType(siteType: SiteType): 'project' | 'network' {
  if (siteType === SiteType.MONITORED_NETWORK) {
    return 'network';
  }
  return 'project';
}
