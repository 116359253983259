import {
  Box,
  Button,
  Heading,
  useDisclosure,
  Text,
  Link,
  Stack,
} from '@chakra-ui/react';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useIntercom } from 'react-use-intercom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SvgImageSmartVMS from '../../svg/smart-VMS.svg?react';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { pathSiteVmsBoards, pathSitesEdit } from '../../constants/path';
import useRoutesData from '../../data/useRoutesData';

export default function SmartVMSTrigger() {
  const intercom = useIntercom();
  const { formatMessage } = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { track } = useAnalytics();
  const { insightId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteId');

  const routesDataHandler = useRoutesData(siteId ? Number(siteId) : undefined);

  if (!siteId) {
    return null;
  }

  const handleOpenClick = () => {
    track('Add Smart VMS Opened', {
      referrer: 'Add Smart VMS button',
    });

    if (siteId) {
      if (routesDataHandler.data && routesDataHandler.data.length > 0) {
        navigate(pathSiteVmsBoards(insightId, siteId));
      } else {
        onOpen();
      }
    } else {
      onOpen();
    }
  };

  const handleConfirmClick = () => {
    track('Talk about smart VMS clicked', {
      referrer: 'Talk about VMS button',
    });

    if (
      siteId &&
      (!routesDataHandler.data || routesDataHandler.data.length === 0)
    ) {
      navigate({
        pathname: pathSitesEdit(insightId),
        search: siteId ? `?siteId=${siteId}` : '',
      });
    } else {
      intercom.showNewMessage('I want to know more about using smart VMS');
    }

    onClose();
  };

  return (
    <>
      <Button
        size={{ base: 'sm', md: 'md' }}
        variant="solid"
        colorScheme="gray"
        color="gray.700"
        layerStyle="floating"
        backgroundColor="white"
        onClick={handleOpenClick}
      >
        <FormattedMessage
          defaultMessage="Add smart VMS"
          id="Jxyzne"
          description="Add smart VMS button label"
        />
      </Button>
      <ConfirmationModal
        open={isOpen}
        onCancel={onClose}
        title={formatMessage({
          defaultMessage: 'Add smart VMS',
          id: 'gyfHpc',
        })}
        confirmButtonText={
          routesDataHandler.data && routesDataHandler.data.length > 0
            ? formatMessage({
                defaultMessage: 'Talk to us about smart VMS',
                id: 'ycbC6x',
              })
            : formatMessage({
                defaultMessage: 'Create routes',
                id: 'GxezQM',
              })
        }
        body={
          <>
            <Heading fontWeight="bold" fontSize="3xl" color="gray.700">
              <FormattedMessage
                defaultMessage="Reduce complaints and manage road users expectations"
                id="9tUwyy"
              />
            </Heading>

            <Text fontSize="sm" fontWeight="bold" mb={8} mt={3}>
              <FormattedMessage
                defaultMessage="with smart mobile VMS messaging"
                id="c7+lI/"
              />
            </Text>

            <Box
              width="100%"
              height={{ base: '123px', md: '160px' }}
              borderRadius="32px"
              backgroundColor="yellow.100"
            >
              <SvgImageSmartVMS width="90%" height="100%" />
            </Box>

            {routesDataHandler.data && routesDataHandler.data.length > 0 ? (
              <>
                <Text fontSize="sm" mt={8}>
                  <FormattedMessage
                    defaultMessage="Our integration with existing VMS offerings allows:"
                    id="5GZ2Wi"
                  />
                </Text>
                <Box ml={2}>
                  <li>
                    <Text as="span" fontSize="sm" fontWeight="bold">
                      <FormattedMessage
                        defaultMessage="Dynamically changing alternate routes so that you're always suggesting the optimum route"
                        id="68hcS/"
                      />
                    </Text>
                  </li>
                  <li>
                    <Text as="span" fontSize="sm" fontWeight="bold">
                      <FormattedMessage
                        defaultMessage="Displaying live journey times with no extra hardware"
                        id="LYNcVx"
                      />
                    </Text>
                  </li>
                  <li>
                    <Text as="span" fontSize="sm" fontWeight="bold">
                      <FormattedMessage
                        defaultMessage="Improve safety with conditional messaging based on speed"
                        id="L+Fo+X"
                      />
                    </Text>
                  </li>
                </Box>
              </>
            ) : (
              <Stack spacing={4} mt={5}>
                <Text as="span" fontSize="sm" fontWeight="bold">
                  <FormattedMessage
                    defaultMessage="Create routes to monitor"
                    id="TjdB7p"
                  />
                </Text>
                <Text fontSize="sm">
                  <FormattedMessage
                    defaultMessage="Before we start, you'll need to add routes to monitor so that we can provide journey time, queueing or speed advice."
                    id="/jNVHH"
                  />
                </Text>
              </Stack>
            )}

            <Box mb={4}>
              <Link
                textDecoration="underline"
                fontSize="sm"
                isExternal
                href="https://www.mooven.com/landing/smart-vms?utm_source=mooven_app&utm_medium=modal"
              >
                <FormattedMessage
                  defaultMessage="Find out more about the capabilities of smart VMS here"
                  id="Lqpgpf"
                />
              </Link>
            </Box>
          </>
        }
        onConfirm={handleConfirmClick}
      />
    </>
  );
}
