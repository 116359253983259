import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api/auth';
import { QS_RESET_PASSWORD_TOKEN_KEY } from '../../constants/clientConstants';
import { pathLogin } from '../../constants/path';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useRedirectAfterLogin } from '../../hooks/useRedirectAfterLogin';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import { errorReport } from '../../utils/errors';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function Login() {
  const { track } = useAnalytics();
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const redirectAfterLogin = useRedirectAfterLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null);
  const {
    register,
    setError,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const resetToken = params.get(QS_RESET_PASSWORD_TOKEN_KEY);
    if (!resetToken) {
      navigate(pathLogin(), { state: redirectAfterLogin });
    } else {
      setToken(resetToken);
    }
  }, [location.search, navigate, redirectAfterLogin]);

  const checkConfirmPassword = (
    confirmPassword: FormValues['confirmPassword']
  ) => {
    const formValues = getValues();
    if (confirmPassword && confirmPassword !== formValues.password) {
      return false;
    }
    return true;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      if (token) {
        const response = await resetPassword(token, values.password);
        if (response.status && response.status < 299) {
          successToast({
            title: formatMessage({
              defaultMessage:
                'You have successfully changed your password. You may now log in.',
              id: 'hHRNqR',
              description: 'Successful password reset toast message',
            }),
          });
          track('User Password Reset', { referrer: 'Password Reset' });
          navigate(pathLogin());
        } else {
          switch (response.message) {
            case 'There was an error when trying to reset your password. Please try again later or contact support.': {
              failureToast({
                title: formatMessage({
                  defaultMessage:
                    'There was an error when trying to reset your password. Please try again later or contact support.',
                  id: 'iZcQPe',
                  description: 'Reset password error message',
                }),
              });
              break;
            }
            case 'Your password is not strong enough. It needs to be at least 8 characters long, include a mixture of numbers, and upper and lowercase letters.': {
              const errMsg = formatMessage({
                defaultMessage:
                  'Please ensure your password has at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
                id: 'gIFPvq',
                description: 'Set password strength error message',
              });
              failureToast({
                title: errMsg,
              });
              setError(
                'password',
                { type: 'custom', message: errMsg },
                { shouldFocus: true }
              );
              setError('confirmPassword', { type: 'custom' });
              break;
            }
            case 'Your reset password token has expired or is invalid. Select the Forgot Password link to resend.': {
              failureToast({
                title: formatMessage({
                  defaultMessage:
                    'Your token has expired or is invalid. Please request a new password reset via the Forgot Password link.',
                  id: 'phbzO4',
                  description:
                    'Reset password expired invalid token error message',
                }),
              });
              navigate(pathLogin());
              break;
            }
            default: {
              errorReport.handled(
                new Error('Reset password error (unknown response)'),
                { response }
              );
              failureToast({
                title: formatMessage({
                  defaultMessage:
                    'There was an error when trying to reset your password. Please try again later or contact support.',
                  id: 'iZcQPe',
                  description: 'Reset password error message',
                }),
              });
              break;
            }
          }
        }
      }
    } catch (e) {
      errorReport.critical(e);
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an error when trying to reset your password. Please try again later or contact support.',
          id: 'iZcQPe',
          description: 'Reset password error message',
        }),
      });
    }
  };

  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading size="4xl" mb={4}>
          <FormattedMessage
            defaultMessage="Reset your password"
            id="qV44Xi"
            description="Reset password header"
          />
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={10}>
            <Text fontSize="2xl">
              <FormattedMessage
                defaultMessage="Set a new password"
                id="RWFnYe"
                description="Reset password instructions"
              />
            </Text>
          </Box>
          <FormControl mb={4} isInvalid={!!errors.password}>
            <Input
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage({
                defaultMessage: 'Password',
                id: 'YXIKM7',
                description: 'Password email input placeholder',
              })}
              aria-label="password"
              {...register('password', {
                required: formatMessage({
                  defaultMessage: 'Please enter password',
                  id: 'TIrT2w',
                  description: 'Error message for password',
                }),
              })}
            />
            {errors?.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
            <FormHelperText>
              {formatMessage({
                defaultMessage:
                  'Must have at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
                id: 'Mq+9D/',
                description: 'create account password input helper text',
              })}
            </FormHelperText>
          </FormControl>
          <FormControl mb={8} isInvalid={!!errors.confirmPassword}>
            <Input
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage({
                defaultMessage: 'Confirm password',
                id: 'ypxIsX',
                description: 'Confirm password email input placeholder',
              })}
              aria-label="confirm-password"
              {...register('confirmPassword', {
                required: formatMessage({
                  defaultMessage: 'Please enter confirm password',
                  id: 'GJYY+y',
                  description: 'Error message for confirm password',
                }),
                validate: {
                  checkConfirmPassword,
                },
              })}
            />
            {errors.confirmPassword && (
              <FormErrorMessage>
                {errors.confirmPassword.message}
              </FormErrorMessage>
            )}
            {errors.confirmPassword &&
              errors.confirmPassword.type === 'checkConfirmPassword' && (
                <FormErrorMessage>
                  {formatMessage({
                    defaultMessage: 'Please make sure your password matches',
                    id: 'ElIH9F',
                    description: 'Error message for confirm password',
                  })}
                </FormErrorMessage>
              )}
          </FormControl>
          <HStack mb={10} spacing={4}>
            <Button
              isLoading={isSubmitting}
              variant="solid"
              colorScheme="brand"
              type="submit"
              isDisabled={!isValid || isSubmitting}
            >
              <FormattedMessage
                defaultMessage="Reset password"
                id="WQ6hYF"
                description="Reset password action"
              />
            </Button>
          </HStack>
        </form>
      </Box>
    </Flex>
  );
}
