import { fetchWithError } from '../utils/fetch';
import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';

const URL_AUTH_LOGIN = `${envConfig.API_URL}/api/auth/login`;
const URL_AUTH_LOGOUT = `${envConfig.API_URL}/api/auth/logout`;
const URL_FORGOT_PASSWORD = `${envConfig.API_URL}/api/auth/forgottenPassword`;
const URL_MFA_REQUEST_RESEND = `${envConfig.API_URL}/api/auth/resendmfa`;
const URL_MFA_SUBMIT_CODE = `${envConfig.API_URL}/api/auth/mfa`;
const URL_PASSWORD_RESET = `${envConfig.API_URL}/api/auth/resetPassword`;

export type AuthResponse = components['schemas']['AuthResponse'];

export async function login(
  username: string,
  password: string,
  fingerprint: string
) {
  const data = { login: username, password, fingerprint };
  const response = await fetchWithError<AuthResponse>(URL_AUTH_LOGIN, {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return response;
}

export async function logout() {
  const response = await fetchWithError<AuthResponse>(URL_AUTH_LOGOUT, {
    method: 'POST',
  });

  return !response.authenticated;
}

export async function submitMFA(mfaCode: string, fingerprint: string | null) {
  const data = { code: mfaCode, fingerprint };

  const response = await fetchWithError<AuthResponse>(URL_MFA_SUBMIT_CODE, {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return response;
}

export async function requestNewMFACode() {
  const response = await fetchWithError<string>(URL_MFA_REQUEST_RESEND, {
    method: 'POST',
  });

  return response;
}

export async function requestPasswordReset(email: string) {
  const response = await fetchWithError<AuthResponse>(URL_FORGOT_PASSWORD, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  return response;
}

export async function resetPassword(token: string, password: string) {
  const response = await fetchWithError<AuthResponse>(URL_PASSWORD_RESET, {
    method: 'POST',
    body: JSON.stringify({ token, password }),
  });

  return response;
}
