import { defineMessage, MessageDescriptor } from 'react-intl';
import { envConfig } from '../config/envConfig';
import {
  pathLogout,
  pathSettings,
  pathSitesManage,
  pathTeamSettings,
  TeamSectionFragment,
} from './path';

export type NavigationItem = {
  path: string;
  label: MessageDescriptor;
};

export const mainNavigationMenuItems = (
  insightId: string | null,
  isAdmin: boolean,
  canEditOrg: boolean
) =>
  [
    ...(canEditOrg && insightId
      ? [
          {
            path: pathTeamSettings(insightId, TeamSectionFragment.MEMBERS),
            label: defineMessage({
              defaultMessage: 'Team Settings',
              id: 'iGos2v',
              description: 'Main navigation team settings label',
            }),
          },
        ]
      : []),
    ...(insightId
      ? [
          {
            path: pathSitesManage(insightId),
            label: defineMessage({
              defaultMessage: 'Account overview',
              id: 'uQAkQS',
              description: 'Main navigation account overview label',
            }),
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            path: envConfig.ADMIN_URL,
            label: defineMessage({
              defaultMessage: 'Admin',
              id: 'eZaI+1',
              description: 'Main navigation admin label',
            }),
          },
        ]
      : []),
    {
      path: pathSettings(),
      label: defineMessage({
        defaultMessage: 'My profile',
        id: 'SkBd7t',
        description: 'Main navigation my profile label',
      }),
    },
    {
      path: pathLogout(),
      label: defineMessage({
        defaultMessage: 'Logout',
        id: 'VVuHpZ',
        description: 'Main navigation logout label',
      }),
    },
  ] as NavigationItem[];
