import { useMemo } from 'react';
import useSWR from 'swr';
import { changePassword, updateProfile } from '../api/user';
import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';
import { fetchWithError, standardFetch } from '../utils/fetch';
import { useUserRoles } from '../hooks/useUserRoles';

type User = components['schemas']['User'];

// Separate SWR cache keys for active vs. onboarding users as we don't want
// regular 401s to show up as errors during onboarding
export const USER_SWR_CACHE_KEY = `${envConfig.API_URL}/api/v1/user`;
export const ONBOARD_USER_SWR_CACHE_KEY = `${envConfig.API_URL}/api/v1/onboard/user`;

export default function useCurrentUserData() {
  const { isAuthRole, isReadRole } = useUserRoles();
  const isLoggedIn = useMemo(
    () => isAuthRole || isReadRole,
    [isAuthRole, isReadRole]
  );
  const {
    data,
    error,
    isValidating,
    mutate,
    // TODO: validate based on local storage user identifier.
  } = useSWR<User>(isLoggedIn ? USER_SWR_CACHE_KEY : null, standardFetch, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
  return {
    data,
    error,
    isValidating,
    updateUserProfile: async (
      firstName: string,
      lastName: string,
      isChangePassword: boolean,
      oldPassword?: string,
      newPassword?: string
    ) => {
      if (firstName !== data?.firstName || lastName !== data?.lastName) {
        await updateProfile(firstName, lastName);
      }
      if (isChangePassword) {
        await changePassword(oldPassword ?? '', newPassword ?? '');
      }
      mutate();
    },
  };
}

interface OnboardParams {
  shouldFetch: boolean;
  token?: string;
}

export function useOnboardUser({ shouldFetch, token }: OnboardParams) {
  const fetcher = async () => {
    const headers = new Headers();
    if (token) {
      headers.append('OnboardAuthorization', `Bearer ${token}`);
    }
    const response = await fetchWithError<User>(USER_SWR_CACHE_KEY, {
      headers,
    });
    return response;
  };

  const { data, error } = useSWR(
    shouldFetch ? ONBOARD_USER_SWR_CACHE_KEY : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading: !data && !error };
}
