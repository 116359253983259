import { useSWRConfig } from 'swr';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { performLogout } from '../../utils/auth';

export default function Logout() {
  const { mutate } = useSWRConfig();
  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    performLogout(mutate);
    shutdown();
    boot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Navigate to="/login" />;
}
