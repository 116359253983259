import useSWR from 'swr';
import { envConfig } from '../config/envConfig';
import { components } from '../types/schema';

export type InsightDetails = components['schemas']['InsightDetail'];

export default function useInsightData(insightId?: string) {
  const { data, error, isValidating } = useSWR<InsightDetails>(
    insightId ? `${envConfig.API_URL}/api/v1/insights/${insightId}` : null
  );

  return {
    data,
    error,
    isValidating,
  };
}
