import { useEffect } from 'react';
import useLocalStorageState from './useLocalStorageState';

export default function usePersistentCountdown(
  key: string,
  initialValue: number
) {
  const [timeRemaining, setTimeRemaining] = useLocalStorageState(
    key,
    initialValue
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prev) => prev - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeRemaining, setTimeRemaining]);

  return [timeRemaining, setTimeRemaining] as const;
}
