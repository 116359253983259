import { useToast } from '@chakra-ui/react';
import { UseToastOptions } from '@chakra-ui/toast';

export const useSuccessToast = (options?: UseToastOptions) =>
  useToast({
    status: 'success',
    variant: 'solid',
    isClosable: true,
    duration: 3000,
    position: 'bottom',
    ...options,
  });
