import { Box } from '@chakra-ui/react';
import RadioGroup from 'design-system/molecules/radio-group';
import { useIntl } from 'react-intl';
import SvgImageProject from '../../svg/site-type-project.svg?react';
import SvgImageNetwork from '../../svg/site-type-network.svg?react';
import { SiteType } from './types';

export default function SiteTypeSelector({
  value,
  onChange,
}: {
  value?: SiteType;
  onChange?: (newValue: SiteType) => void;
}) {
  const { formatMessage } = useIntl();
  const options = [
    {
      value: SiteType.PROJECT_SITE,
      label: formatMessage({
        id: 'ql2r9w',
        defaultMessage: 'Project site',
        description: 'site type selector title',
      }),
      description: formatMessage({
        id: '3YsYVo',
        defaultMessage:
          "You require an understanding of disruption - whether it's to local businesses, public transport or regular traffic - to a specific area that has upcoming work.",
        description: 'site type selector description',
      }),
      image: <SvgImageProject width="100%" height="100%" />,
    },
    {
      value: SiteType.MONITORED_NETWORK,
      label: formatMessage({
        id: 'cZzUph',
        defaultMessage: 'Network',
        description: 'site type selector title',
      }),
      description: formatMessage({
        id: 'ObuRAf',
        defaultMessage:
          "You require a longer term understanding of a larger area to allow you to react when traffic patterns aren't behaving as they should be.",
        description: 'site type selector description',
      }),
      image: <SvgImageNetwork width="100%" height="100%" />,
    },
  ];
  return (
    <Box data-testid="site-create-type-options">
      <RadioGroup
        options={options}
        onSelectionChange={onChange}
        selection={value}
      />
    </Box>
  );
}
