import { FunctionComponent, useState, useEffect, useMemo } from 'react';

export default function WrapComponent<
  TWrapperProps extends {},
  TComponentProps = {
    Wrapper: FunctionComponent<TWrapperProps>;
  },
>({
  Wrapper,
  Component,
}: {
  Wrapper: FunctionComponent<TWrapperProps>;
  Component: FunctionComponent<TComponentProps>;
});
export default function WrapComponent<
  TWrapperProps extends {},
  TComponentProps extends {
    Wrapper: FunctionComponent<TWrapperProps>;
    [key: string]: any;
  },
>({
  Wrapper,
  Component,
  componentProps,
}: {
  Wrapper: FunctionComponent<TWrapperProps>;
  Component: FunctionComponent<TComponentProps>;
  componentProps: Omit<TComponentProps, 'Wrapper'>;
});

export default function WrapComponent<
  TWrapperProps extends {},
  TComponentProps extends {
    Wrapper: FunctionComponent<TWrapperProps>;
  },
>({
  Wrapper,
  Component,
  componentProps,
}: {
  Wrapper: FunctionComponent<TWrapperProps>;
  Component: FunctionComponent<TComponentProps>;
  componentProps?: Omit<TComponentProps, 'Wrapper'>;
}) {
  const [proxyProps, setProxyProps] = useState<TWrapperProps>();
  const ProxyComponent = useMemo(
    () => getProxyComponent(setProxyProps),
    [setProxyProps]
  );
  const rendererResult = useMemo(() => {
    const combinedProps = {
      ...(componentProps ?? {}),
      Wrapper: ProxyComponent,
    } as TComponentProps;
    return <Component {...combinedProps} />;
  }, [Component, ProxyComponent, componentProps]);

  return (
    <>
      {proxyProps && <Wrapper {...proxyProps} />}
      {rendererResult}
    </>
  );
}
function getProxyComponent(stateUpdater) {
  return function ProxyComponent(props) {
    useEffect(() => {
      stateUpdater(props);
      return () => {
        stateUpdater(undefined);
      };
    }, [props]);
    return null;
  };
}
