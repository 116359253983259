import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { STORAGE_CHANGE_EV } from '../utils/local-storage-events';

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  defaultValue: T,
  toVal: (it: string) => T
) {
  const [value, setValue] = useState<T>(initialValue);

  const onStorageChange = debounce(() => {
    const rawValue = localStorage.getItem(key);
    const parsedValue = rawValue ? toVal(rawValue) : defaultValue;
    if (!isEqual(parsedValue, value)) {
      setValue(parsedValue);
    }
  }, 100);

  useEffect(() => {
    document.addEventListener(STORAGE_CHANGE_EV, onStorageChange, true);

    return () => {
      document.removeEventListener(STORAGE_CHANGE_EV, onStorageChange);
    };
  });

  return value;
}
