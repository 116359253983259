import { ComponentType, ReactNode } from 'react';
import {
  useRadioGroup,
  UseRadioGroupProps,
  useRadio,
  Box,
  chakra,
} from '@chakra-ui/react';

type UseRadioGroupHook = ReturnType<typeof useRadioGroup>;
interface RadioOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}
export interface CustomRadioComponentProps<
  TOption extends RadioOption = RadioOption,
> {
  option: TOption;
  stateProps: {
    'data-active': string | undefined;
    'data-checked': string | undefined;
    'data-disabled': string | undefined;
    'data-focus': string | undefined;
    'data-focus-visible': string | undefined;
  };
}

export default function CustomRadioGroup<TOption extends RadioOption>({
  radioGroupProps,
  options,
  renderRootComponent,
  renderRadioComponent,
}: {
  radioGroupProps: UseRadioGroupProps;
  options: TOption[];
  renderRadioComponent: ComponentType<CustomRadioComponentProps<TOption>>;
  renderRootComponent?: ComponentType<{ children: ReactNode }>;
  dataTestId?: string;
}) {
  const { getRadioProps: getRadioGroupProps, isDisabled: isGroupDisabled } =
    useRadioGroup(radioGroupProps);
  const Root = renderRootComponent ?? Box;
  return (
    <Root>
      {options.map((option) => (
        <CustomRadioElement
          key={option.value}
          option={option}
          getRadioGroupProps={getRadioGroupProps}
          renderRadioComponent={renderRadioComponent}
          isGroupDisabled={isGroupDisabled}
        />
      ))}
    </Root>
  );
}
function CustomRadioElement<TOption extends RadioOption>({
  option,
  getRadioGroupProps,
  renderRadioComponent: RadioComponent,
  isGroupDisabled,
}: {
  option: TOption;
  getRadioGroupProps: UseRadioGroupHook['getRadioProps'];
  renderRadioComponent: ComponentType<CustomRadioComponentProps<TOption>>;
  isGroupDisabled?: boolean;
}) {
  const radioGroupProps = getRadioGroupProps({
    value: option.value,
    isDisabled: isGroupDisabled || option.isDisabled,
  });
  const { getRadioProps, getInputProps } = useRadio(radioGroupProps);
  const radioProps = getRadioProps();
  const stateProps = {
    'data-active': radioProps['data-active'],
    'data-checked': radioProps['data-checked'],
    'data-disabled': radioProps['data-disabled'],
    'data-focus': radioProps['data-focus'],
    'data-focus-visible': radioProps['data-focus-visible'],
  };
  return (
    <chakra.label cursor="pointer">
      <input {...getInputProps()} />
      <RadioComponent option={option} stateProps={stateProps} />
    </chakra.label>
  );
}
