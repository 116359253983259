import { Children, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import CustomRadioGroup, {
  CustomRadioComponentProps,
} from './custom-radio-group';

export interface RadioGroupOption<TValue extends string = string> {
  value: TValue;
  label: string;
  description?: ReactNode;
  image?: ReactNode;
  allowComment?: boolean;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  wrapperProps?: BoxProps;
}

export default function RadioGroup<
  TValue extends string,
  TOption extends RadioGroupOption<TValue>,
>({
  options,
  defaultSelection,
  selection,
  onSelectionChange,
  comment = '',
  onCommentChange = () => {},
  direction = 'column',
  dataTestId,
}: {
  options: TOption[];
  defaultSelection?: TOption['value'];
  selection?: TOption['value'];
  onSelectionChange?: (newValue: TOption['value']) => void;
  comment?: string;
  onCommentChange?: (newValue: string) => void;
  direction?: 'column' | 'row';
  dataTestId?: string;
}) {
  const optionsWithComments = options.map((option: any) => ({
    ...option,
    comment,
    onCommentChange,
    dataTestId,
  }));
  return (
    <CustomRadioGroup
      radioGroupProps={{
        defaultValue: defaultSelection,
        value: selection,
        onChange: onSelectionChange,
      }}
      options={optionsWithComments}
      renderRadioComponent={RadioCard}
      renderRootComponent={
        direction === 'column' ? GroupWrapperColumn : GroupWrapperRow
      }
    />
  );
}
function GroupWrapperRow({ children }: { children: ReactNode }) {
  return (
    <SimpleGrid columns={Children.count(children)} spacing={4}>
      {children}
    </SimpleGrid>
  );
}
function GroupWrapperColumn({ children }: { children: ReactNode }) {
  return (
    <VStack alignItems="stretch" spacing={4} width="full">
      {children}
    </VStack>
  );
}

function RadioCard<TValue extends string>({
  stateProps,
  option: {
    label,
    description,
    image,
    allowComment,
    comment = '',
    onCommentChange,
    isHighlighted,
    wrapperProps,
    dataTestId,
  },
}: {
  stateProps: CustomRadioComponentProps['stateProps'];
  option: RadioGroupOption<TValue> & {
    comment: string;
    onCommentChange: (newValue: string) => void;
    dataTestId?: string;
  };
}) {
  const isChecked = stateProps['data-checked'] !== undefined;
  const [commentState, setCommentState] = useState<string>(
    allowComment && isChecked ? comment : ''
  );
  const lastChecked = useRef(isChecked);
  useEffect(() => {
    if (isChecked === true && lastChecked.current === false) {
      onCommentChange(commentState);
    }
    lastChecked.current = isChecked;
  }, [isChecked, onCommentChange, commentState]);
  return (
    <VStack
      {...stateProps}
      data-group
      width="full"
      height="full"
      alignItems="left"
      spacing={2}
      border="solid"
      data-testid={dataTestId}
      borderRadius="16px"
      backgroundColor="white"
      borderColor={isHighlighted ? 'gray.300' : 'gray.200'}
      borderWidth={isHighlighted ? '2px' : '1px'}
      padding={isHighlighted ? '15px' : '16px'}
      _hover={{
        borderColor: 'gray.300',
        borderWidth: '2px',
        padding: '15px',
      }}
      _checked={{
        borderColor: 'green.400',
        boxShadow: 'soft',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
      _disabled={{
        borderColor: 'gray.200',
        borderWidth: '1px',
        padding: '16px',
      }}
      {...(wrapperProps ?? {})}
    >
      {image && (
        <Box
          width="100%"
          height={{ base: '123px', md: '160px' }}
          borderRadius="16px"
          backgroundColor="#FFFDDF"
          _groupChecked={{
            backgroundColor: 'yellow.100',
          }}
        >
          {image}
        </Box>
      )}
      <Flex gap={2}>
        <Box pt={1} pb={1} flex="0">
          <Box
            width={4}
            height={4}
            backgroundColor="white"
            border="solid 2px"
            borderColor="gray.200"
            borderRadius="full"
            _groupChecked={{
              borderWidth: '5px',
              borderColor: 'green.500',
            }}
          />
        </Box>
        <VStack flex="1" alignItems="stretch" spacing="2">
          <Box>
            <Text
              fontSize="16px"
              mb="0"
              color="gray.900"
              fontWeight="bold"
              _groupDisabled={{
                color: 'gray.500',
              }}
            >
              {label}
            </Text>
            <Text
              fontSize="12px"
              mb="0"
              color="gray.700"
              _groupDisabled={{
                color: 'gray.400',
              }}
            >
              {description}
            </Text>
          </Box>
          {isChecked && allowComment && (
            <Box>
              <Textarea
                value={commentState}
                height="80px"
                onChange={(e) => {
                  setCommentState(e.target.value);
                  onCommentChange(e.target.value);
                }}
                autoFocus
              />
            </Box>
          )}
        </VStack>
      </Flex>
    </VStack>
  );
}
