import { Link } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import MoovenLogo from '../svg/mooven-logo.svg?react';

export default function ErrorPage({
  text,
  noLink = false,
}: {
  text: string;
  noLink?: boolean;
}) {
  return (
    <>
      <Box p={4}>
        <MoovenLogo width="150" />
      </Box>
      <Box textAlign="center" p={4}>
        <Heading mb={4} color="gray.500">
          {text}
        </Heading>
        {!noLink && <Link to="/">Go to homepage</Link>}
      </Box>
    </>
  );
}
