export type TimePeriodType =
  | 'TIME_PERIOD_LAST_24_HOURS'
  | 'TIME_PERIOD_LAST_WEEK'
  | 'TIME_PERIOD_LAST_MONTH'
  | 'TIME_PERIOD_CUSTOM';
export function IsTimePeriodType(value: unknown): value is TimePeriodType {
  return (
    value === 'TIME_PERIOD_LAST_24_HOURS' ||
    value === 'TIME_PERIOD_LAST_WEEK' ||
    value === 'TIME_PERIOD_LAST_MONTH' ||
    value === 'TIME_PERIOD_CUSTOM'
  );
}

export interface TimePeriodCustom {
  type: 'TIME_PERIOD_CUSTOM';
  startDate: number;
  endDate: number;
  compareDate?: number;
}
export interface TimePeriodFixed {
  type:
    | 'TIME_PERIOD_LAST_24_HOURS'
    | 'TIME_PERIOD_LAST_WEEK'
    | 'TIME_PERIOD_LAST_MONTH';
  compareDate?: number;
}
export type TimePeriod = TimePeriodCustom | TimePeriodFixed;
