import { defineMessage, MessageDescriptor } from 'react-intl';

export const MESSAGE_BUTTON_SAVE: MessageDescriptor = defineMessage({
  defaultMessage: 'Save',
  id: 'iE7RWe',
  description: 'Save button label.',
});
export const MESSAGE_BUTTON_EDIT: MessageDescriptor = defineMessage({
  defaultMessage: 'Edit',
  id: '8QfaZu',
  description: 'Edit button label.',
});
export const MESSAGE_BUTTON_DELETE: MessageDescriptor = defineMessage({
  defaultMessage: 'Delete',
  id: 'oBM96J',
  description: 'Delete button label.',
});
export const MESSAGE_BUTTON_REMOVE: MessageDescriptor = defineMessage({
  defaultMessage: 'Remove',
  id: '+5RRn/',
  description: 'Remove button label.',
});
export const MESSAGE_BUTTON_CANCEL: MessageDescriptor = defineMessage({
  defaultMessage: 'Cancel',
  id: 'ojD12V',
  description: 'Cancel button label.',
});
export const MESSAGE_UNIT_SYSTEM_METRIC: MessageDescriptor = defineMessage({
  defaultMessage: 'Metric',
  id: 'n+qnFE',
  description: 'Metric system name.',
});
export const MESSAGE_UNIT_SYSTEM_IMPERIAL: MessageDescriptor = defineMessage({
  defaultMessage: 'Imperial',
  id: 'RtO18F',
  description: 'Imperial system name.',
});
export const MESSAGE_GENERIC_ERROR: MessageDescriptor = defineMessage({
  defaultMessage: 'It looks like something went wrong, try reloading the page.',
  id: 'ammgns',
  description: 'Message for unknown error.',
});

export const MESSAGE_UI_DISABLED_SITE_PERMISSION: MessageDescriptor =
  defineMessage({
    defaultMessage: 'You don’t have permission to edit this site.',
    id: 'P+dFVN',
    description: 'Message from disabled UI elements for read only users.',
  });

export const MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION: MessageDescriptor =
  defineMessage({
    defaultMessage:
      'You don’t have permission to turn monitor on/off this site.',
    id: 'wpOTJU',
    description:
      'Message from disabled UI elements for users do not have monitor permission.',
  });

export const MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION: MessageDescriptor =
  defineMessage({
    defaultMessage:
      'Editor permissions are required to add, edit or delete routes, please contact an editor if interested.',
    id: 'KskdOx',
    description:
      'Message from disabled UI elements for users do not have edit site route permission.',
  });

export const MESSAGE_UI_DISABLED_HAS_COLLECTED_DATA: MessageDescriptor =
  defineMessage({
    defaultMessage:
      'Some route properties cannot be edited after data collection has started.',
    id: 'pGT/d5',
    description:
      'Message from disabled UI elements when a route has started collecting data.',
  });

export const MESSAGE_BUTTON_ADD_MANAGE_USERS: MessageDescriptor = defineMessage(
  {
    defaultMessage: 'Add & Manage Users',
    id: '9x8phw',
    description: 'Add & Manage Users',
  }
);

export const MESSAGE_BUTTON_CLOSE: MessageDescriptor = defineMessage({
  defaultMessage: 'Close',
  id: 'De70Sr',
  description: 'Close button label.',
});

export const MESSAGE_BROWSE_AWAY_UNSAVED_CHANGES: MessageDescriptor =
  defineMessage({
    defaultMessage:
      'Are you sure you want to leave this page? Unsaved changes will be discarded.',
    id: 'QTeP1X',
    description:
      'Prompt warning when trying to navigate away from an unsaved form.',
  });

export const MESSAGE_CANCEL_UNSAVED_CHANGES: MessageDescriptor = defineMessage({
  defaultMessage: 'Discard unsaved changes?',
  id: 'QFg8YX',
  description: 'Prompt warning when trying to cancel form.',
});

export const MESSAGE_ERROR_SITE_NOT_FOUND: MessageDescriptor = defineMessage({
  defaultMessage: 'Site not found',
  id: 'eA4487',
  description: 'Prompt warning when trying to cancel form.',
});

export const MESSAGE_PLAN_INSIGHT_TYPE_DELAY: MessageDescriptor = defineMessage(
  {
    defaultMessage: 'Delay',
    id: 'I/ip/E',
    description: 'Plan insight mode option label for: Delay',
  }
);

export const MESSAGE_PLAN_INSIGHT_TYPE_TIME: MessageDescriptor = defineMessage({
  defaultMessage: 'Journey time',
  id: 'HOt6l6',
  description: 'Plan insight mode option label for: Journey time',
});

export const MESSAGE_PLAN_INSIGHT_TYPE_SPEED: MessageDescriptor = defineMessage(
  {
    defaultMessage: 'Speed',
    id: 'p+BnAb',
    description: 'Plan insight mode option label for: Speed',
  }
);

export const MESSAGE_PLAN_INSIGHT_TYPE_IMPACT: MessageDescriptor =
  defineMessage({
    defaultMessage: 'Site impact',
    id: 'BgoUR1',
    description: 'Plan insight mode option label for: Site impact',
  });

export const MESSAGE_PLAN_INSIGHT_TYPE_VEHICLE_COUNT: MessageDescriptor =
  defineMessage({
    defaultMessage: 'Vehicle count',
    id: 'EEUxHX',
    description: 'Plan insight mode option label for: Vehicle count',
  });
