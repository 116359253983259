import { ScopedMutator } from 'swr/_internal';
import {
  ROLE_PRE_AUTH_USER,
  ROLE_READ_USER,
  ROLE_STORMPATH_USER,
} from '../constants/roles';
import { logout } from '../api/auth';

export function checkLoggedIn() {
  return localStorage.getItem('isLoggedIn') === '1';
}
export function checkMFARole() {
  return localStorage.getItem('currentRole') === ROLE_PRE_AUTH_USER;
}
export function checkAuthRole() {
  return localStorage.getItem('currentRole') === ROLE_STORMPATH_USER;
}
export function checkReadRole() {
  return localStorage.getItem('currentRole') === ROLE_READ_USER;
}

export async function clearUserData(mutate: ScopedMutator) {
  // update all SWR keys to undefined (clear SWR cache)

  await mutate(() => true, undefined, { revalidate: false });
  localStorage.setItem('session-id', `${Math.floor(Math.random() * 100)}`);
  localStorage.setItem('roles', '');
  localStorage.setItem('currentRole', '');
  localStorage.setItem('isAdmin', '0');
  localStorage.setItem('isLoggedIn', '0');
}

export async function performLogout(mutate: ScopedMutator) {
  await clearUserData(mutate);
  return logout();
}
