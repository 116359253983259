import { useCallback, useEffect } from 'react';
import 'isomorphic-fetch';
import fetchIntercept from 'fetch-intercept';
import { useLocation, useNavigate, Outlet, Location } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import {
  pathCookies,
  pathDashboard,
  pathForbidden,
  pathForgotPassword,
  pathLogin,
  pathMfaLogin,
  pathResetPassword,
} from '../../constants/path';
import {
  checkAuthRole,
  checkLoggedIn,
  checkMFARole,
  checkReadRole,
  clearUserData,
} from '../../utils/auth';
import { ROLE_PRE_AUTH_USER } from '../../constants/roles';

let interceptResponseHandler = (response) => response;

export default function CheckAuth() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { mutate } = useSWRConfig();
  const redirectToLogin = useCallback(
    (keepData = false) => {
      if (!keepData) {
        clearUserData(mutate);
      }
      navigate(pathLogin(), {
        replace: true,
        state: { redirectAfterLogin: location },
      });
    },
    [navigate, location, mutate]
  );

  useEffect(() => {
    interceptResponseHandler = (response) => {
      if (
        location.pathname !== pathLogin() &&
        location.pathname !== pathForbidden()
      ) {
        if (response.status === 401) {
          response
            .json()
            .then((json) => {
              if (
                json &&
                json.roles !== undefined &&
                json.roles.indexOf(ROLE_PRE_AUTH_USER) > -1
              ) {
                redirectToLogin(true);
              } else {
                redirectToLogin();
              }
            })
            .catch(() => {
              redirectToLogin();
            });
        } else if (response.status === 403) {
          navigate(pathForbidden());
        }
      }
      return response;
    };
  }, [navigate, location, redirectToLogin]);

  useEffect(() => {
    if (localStorage.getItem('loginTime')) {
      localStorage.clear();
    }
    if (!cookiesEnabled()) {
      navigate(pathCookies(), { replace: true });
    } else if (!isLoginPage(location)) {
      if (location.pathname === pathMfaLogin()) {
        if (!checkMFARole()) {
          // Not have role ROLE_PRE_AUTH_USER
          if (!checkLoggedIn()) {
            redirectToLogin();
          } else if (
            !location.state ||
            !location.state.redirectAfterLogin ||
            location.state.redirectAfterLogin === '/'
          ) {
            navigate(pathDashboard(), { replace: true });
          } else {
            navigate(location.state.redirectAfterLogin, { replace: true });
          }
        } else if (!checkLoggedIn()) {
          localStorage.setItem('currentRole', '');
          localStorage.setItem('isAdmin', '');
          redirectToLogin();
        }
      } else if (
        location.pathname === '/' &&
        checkLoggedIn() &&
        (checkAuthRole() || checkReadRole())
      ) {
        navigate(pathDashboard(), { replace: true });
      } else if (isLoginPage(location) && checkLoggedIn()) {
        navigate(pathDashboard(), { replace: true });
      } else {
        // insight page
        // eslint-disable-next-line no-lonely-if
        if (!checkAuthRole() && !checkReadRole()) {
          if (checkMFARole()) {
            navigate(pathMfaLogin(), {
              replace: true,
              state: { redirectAfterLogin: location },
            });
          } else {
            redirectToLogin();
          }
        }
      }
    } else {
      // login page
      // eslint-disable-next-line no-lonely-if
      if (checkLoggedIn()) {
        if (checkMFARole()) {
          navigate(pathMfaLogin(), {
            replace: true,
            state: {
              redirectAfterLogin:
                location.state && location.state.redirectAfterLogin,
            },
          });
        } else if (checkAuthRole() || checkReadRole()) {
          //  ROLE_STORMPATH_USER
          navigate(pathDashboard(), { replace: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Outlet />;
}

function cookiesEnabled() {
  let { cookieEnabled } = navigator;
  if (!cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
  }
  return cookieEnabled;
}

function isLoginPage(location: Location) {
  return (
    location.pathname === pathLogin() ||
    location.pathname === pathForgotPassword() ||
    location.pathname === pathResetPassword()
  );
}

fetchIntercept.register({
  request(url, config) {
    // Empty stub required to stop the library from exploding.
    return [url, config];
  },
  requestError(error) {
    // Empty stub required to stop the library from exploding.
    return Promise.reject(error);
  },
  response(response) {
    return interceptResponseHandler(response);
  },
  responseError(error) {
    // Empty stub required to stop the library from exploding.
    return Promise.reject(error);
  },
});
