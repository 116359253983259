import { envConfig } from '../config/envConfig';
import { standardFetch } from '../utils/fetch';

export async function updateProfile(firstName: string, lastName: string) {
  return standardFetch(`${envConfig.API_URL}/api/v1/user/profile`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      firstName,
      lastName,
    }),
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return standardFetch(`${envConfig.API_URL}/api/auth/changePassword`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  });
}
