import { useRef } from 'react';
import useSiteUserViewData, { UserViews } from '../../data/useSiteUserViewData';
import useSiteView from '../../hooks/useSiteView';
import { DataHandlerFeedback } from '../../utils/dataHandler';

export default function SiteUserViewDefaultSelector({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    state: { siteId },
    setState,
    isStateDefault,
  } = useSiteView();
  const siteUserViewDataHandler = useSiteUserViewData(
    isStateDefault ? { siteId: siteId.toString() } : null
  );
  const { data, isValidating, error } = siteUserViewDataHandler;
  const isDataReady = data || (!isValidating && !error);

  const lastHandledRef = useRef(-1);

  if (isDataReady && lastHandledRef.current !== siteId) {
    lastHandledRef.current = siteId;
    if (isStateDefault) {
      const defaultViewProps = getDefaultView(data);
      if (defaultViewProps) {
        setState(`?siteId=${siteId}&${defaultViewProps.queryString}`);
        // return null to avoid flickering
        return null;
      }
    }
  }

  if (isStateDefault && !isDataReady) {
    return (
      <DataHandlerFeedback dataHandlersParam={[siteUserViewDataHandler]} />
    );
  }
  return children;
}

function getDefaultView(userViewsData?: UserViews) {
  if (!userViewsData || !userViewsData.defaultViewId) {
    return undefined;
  }
  const globalViewDefault = userViewsData.globalViewConfigs?.find(
    (viewConfig) => viewConfig.viewId === userViewsData.defaultViewId
  );
  if (globalViewDefault) {
    return globalViewDefault;
  }
  return userViewsData.viewConfigs?.find(
    (viewConfig) => viewConfig.viewId === userViewsData.defaultViewId
  );
}
