import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Redirect {
  pathname: string;
}

export function useRedirectAfterLogin() {
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<Redirect>({
    pathname: '/dashboard',
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.redirectAfterLogin) {
      setRedirectAfterLogin(location.state.redirectAfterLogin);
    }
  }, [location.state]);

  return redirectAfterLogin;
}
