/* eslint-disable no-restricted-globals */
import { murmurX64Hash128 } from '@fingerprintjs/fingerprintjs';

export function getCustomFingerprint(login: string, password: string) {
  const keys: any[] = [];
  keys.push(navigator.language);
  keys.push(screen.colorDepth);
  keys.push(new Date().getTimezoneOffset());
  keys.push(login);
  keys.push(password);
  keys.push(getBrowserType());
  keys.push(navigator.platform);
  keys.push(getDefaultFontSize());
  return murmurX64Hash128(keys.join('###'), 31);
}
function getBrowserType() {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'Opera';
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  }
  if (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    !!(document as any).documentMode === true
  ) {
    return 'IE';
  }
  return 'Unknown';
}
function getDefaultFontSize() {
  const dom = document.body;
  const who = document.createElement('span');

  who.style.cssText =
    'display:inline-block; padding:0; line-height:1; position:absolute; visibility:hidden; font-size:1em';

  who.appendChild(document.createTextNode('M'));
  dom.appendChild(who);
  const fs = [who.offsetWidth, who.offsetHeight];
  dom.removeChild(who);
  return fs;
}
