import useSWR from 'swr';
import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';
import { standardFetch } from '../utils/fetch';

export type UserViews =
  components['schemas']['UserViewConfigModel_UserConfigView'];
export type UserViewInput = components['schemas']['ViewConfigModel'];
export type UserDefaultViewInput = components['schemas']['ViewConfigDefault'];

export default function useSiteUserViewData(params: null | { siteId: string }) {
  const { data, error, isValidating, mutate } = useSWR<UserViews>(
    params?.siteId
      ? `${envConfig.API_URL}/api/v1/config/site/${params.siteId}/view`
      : null
  );

  return {
    data,
    error,
    isValidating,
    createView: async (view: UserViewInput) => {
      if (!params) {
        throw new Error('Site ID is required to create a view');
      }
      const response = await standardFetch(
        `${envConfig.API_URL}/api/v1/config/site/${params.siteId}/view`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(view),
        }
      );
      await mutate();
      return response;
    },
    deleteView: async (viewId: string) => {
      if (!params) {
        throw new Error('Site ID is required to delete a view');
      }
      const response = await standardFetch(
        `${envConfig.API_URL}/api/v1/config/site/${params.siteId}/view/${viewId}`,
        { method: 'DELETE' }
      );
      await mutate();
      return response;
    },
    updateView: async (viewId: string, config: UserViewInput) => {
      if (!params) {
        throw new Error('Site ID is required to update a view');
      }
      const response = await standardFetch(
        `${envConfig.API_URL}/api/v1/config/site/${params.siteId}/view/${viewId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(config),
        }
      );
      await mutate();
      return response;
    },
    setDefaultView: async (viewId: string, config: UserDefaultViewInput) => {
      if (!params) {
        throw new Error('Site ID is required to set a default view');
      }
      const response = await standardFetch(
        `${envConfig.API_URL}/api/v1/config/site/${params.siteId}/default/view/${viewId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(config),
        }
      );
      await mutate();
      return response;
    },
  };
}
