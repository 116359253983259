import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRouteError } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { errorReport } from '../utils/errors';
import { MESSAGE_GENERIC_ERROR } from '../constants/messages';

export default function RouteErrorBoundary() {
  const { formatMessage } = useIntl();
  const error = useRouteError();
  useEffect(() => {
    errorReport.unknown(error);
  }, [error]);
  return (
    <Box data-testid="error-block" px={12} py={20} textAlign="center">
      {formatMessage(MESSAGE_GENERIC_ERROR)}
    </Box>
  );
}
