import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import { mainNavigationMenuItems } from '../../../constants/mainNavigationMenuItems';
import useCurrentUserData from '../../../data/useCurrentUserData';
import CreateSiteTrigger from '../../../components/CreateSiteTrigger';
import SmartVMSTrigger from '../../../components/SmartVMSTrigger';
import { useUserRoles } from '../../../hooks/useUserRoles';
import { useOrgPermissions } from '../../../hooks/useOrgPermissions';
import { pathImpactAssessmentRequest } from '../../../constants/path';

interface MainNavigationProps {
  showCreateSiteButton?: boolean;
}

const menuButtonStyleProps = {
  size: { base: 'sm', md: 'md' },
  variant: 'solid',
  colorScheme: 'gray',
  color: 'gray.700',
  layerStyle: 'floating',
  backgroundColor: 'white',
};

export default function MainNavigation({
  showCreateSiteButton = true,
}: MainNavigationProps) {
  const { formatMessage } = useIntl();
  const { insightId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteId');

  const { isAdmin } = useUserRoles();
  const currentUser = useCurrentUserData();
  const { data: permissions } = useOrgPermissions(insightId);

  const dropdownItems = mainNavigationMenuItems(
    insightId ?? null,
    isAdmin,
    currentUser.data?.orgAccess?.canEditAll ||
      (currentUser.data?.orgAccess?.canEdit ?? []).indexOf(
        insightId ? Number(insightId) : -1
      ) >= 0
  );

  return (
    <HStack>
      {showCreateSiteButton && insightId && (
        <CreateSiteTrigger
          insightId={insightId}
          renderButton={(onClick) => (
            <Button
              data-testid="create-site-nav-button"
              {...menuButtonStyleProps}
              onClick={onClick}
              isDisabled={
                !permissions || !permissions.includes('CanCreateSite')
              }
            >
              <FormattedMessage
                defaultMessage="New site"
                id="nuxa+S"
                description="Main navigation New site button label"
              />
            </Button>
          )}
        />
      )}

      {insightId && siteId && (
        <Button
          {...menuButtonStyleProps}
          as={Link}
          to={pathImpactAssessmentRequest(insightId, siteId)}
        >
          <FormattedMessage
            defaultMessage="Get impact assessment"
            id="rSTVMo"
          />
        </Button>
      )}

      <SmartVMSTrigger />

      <Menu computePositionOnMount>
        <MenuButton
          as={IconButton}
          {...menuButtonStyleProps}
          maxW="42px"
          verticalAlign="middle"
          _hover={{
            backgroundColor: 'gray.50',
          }}
          aria-label={formatMessage(
            defineMessage({
              defaultMessage: 'Menu',
              id: 'gCxaIp',
              description: 'Aria label for the main navigation menu button',
            })
          )}
          icon={<SettingsIcon />}
        />
        <MenuList>
          {dropdownItems.map((item) =>
            item.path.startsWith('http') ? (
              <MenuItem
                as={ChakraLink}
                href={item.path}
                isExternal
                key={formatMessage(item.label)}
                _hover={{ textDecoration: 'none' }}
              >
                {formatMessage(item.label)}
              </MenuItem>
            ) : (
              <MenuItem
                as={Link}
                to={item.path}
                key={formatMessage(item.label)}
                _hover={{ textDecoration: 'none' }}
              >
                {formatMessage(item.label)}
              </MenuItem>
            )
          )}
        </MenuList>
      </Menu>
    </HStack>
  );
}
