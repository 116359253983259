import { useMemo } from 'react';
import {
  ROLE_ADMIN_USER,
  ROLE_PRE_AUTH_USER,
  ROLE_READ_USER,
  ROLE_STORMPATH_USER,
  ROLE_PRE_AUTH_ONBOARD_NO_FACTOR,
} from '../constants/roles';
import useLocalStorage from './useLocalStorage';

export interface RolesDictionary {
  isAdmin: boolean;
  isMFARole: boolean;
  isAuthRole: boolean;
  isReadRole: boolean;
  isOnboardRole: boolean;
}
export function useUserRoles(): RolesDictionary {
  const roles: string[] = useLocalStorage(
    'roles',
    localStorage.getItem('roles')?.split(',') || [],
    [],
    (rawValue) => rawValue.split(',')
  );
  const currentRole: string | null = useLocalStorage(
    'currentRole',
    localStorage.getItem('currentRole'),
    '',
    (role) => role
  );
  return useMemo(
    () => ({
      isAdmin: roles.includes(ROLE_ADMIN_USER),
      isMFARole: roles.includes(ROLE_PRE_AUTH_USER),
      isAuthRole:
        roles.includes(ROLE_STORMPATH_USER) ||
        currentRole === ROLE_STORMPATH_USER,
      isReadRole: roles.includes(ROLE_READ_USER),
      isOnboardRole: roles.includes(ROLE_PRE_AUTH_ONBOARD_NO_FACTOR),
    }),
    [roles, currentRole]
  );
}
